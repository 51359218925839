// Modules
import React, { useContext } from "react";

// Context
import { AppContext } from "../../ContextProvider";

// Components
import { ChoiceEP } from "./ChoiceEP";
import { ChoicePP } from "./ChoicePP";

// Component / Hook
export const ChoiceScreen = () => {
  const {
    state: { advertisingMaterial },
  } = useContext(AppContext);

  if (advertisingMaterial.type === "ep") {
    return <ChoiceEP />;
  } else {
    return <ChoicePP />;
  }
};
