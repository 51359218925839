// Modules
import React, { Fragment, useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useResponsive } from "react-hooks-responsive";

// Context
import { AppContext } from "../../ContextProvider";

// Config
import { breakpoints } from "../../config";

// Components
import { Intro } from "../../components/Intro";
import { Title } from "../../components/Title";
import { Loading } from "../../components/Loading";
import { FooterNavigation } from "../../components/FooterNavigation";
import { TargetGroupSection } from "../../components/TargetGroup";
import { StickyHeaderContent } from "../../components/StickyHeaderContent";

// Styles
const ColumnWrapper = styled.section<{ size: string }>`
  display: flex;
  margin-top: 150px;
`;

const Column = styled.div`
  flex: 1;
`;

// Component / Hook
export const TargetGroup = () => {
  // States
  const [isLoading, setLoading] = useState(true);
  const [groups, setGroups] = useState({});
  const [size] = useResponsive(breakpoints);

  // Context
  const {
    state: { targetGroups },
  } = useContext(AppContext);

  const loadGroups = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BACKEND}/targetgroups`,
    );
    const { data } = await response.json();

    setGroups(data);
    setLoading(false);
  };

  // Load data on start
  useEffect(() => {
    loadGroups();
  }, []);

  return (
    <Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <StickyHeaderContent>
            <Title label="Zielgruppe" />
            <Intro>
              PACVERTISE nutzt zur Optimierung des Targetings Ihrer PAC Ad
              Werbekampagnen die b4p (best4planning) Daten. Diese b4p Daten
              resultieren aus der Analyse der Zielgruppen von 2.400 Marken sowie
              von 1.500 Medien von Zeitschriften bis hin zu TV, welche die GIK
              (Gesellschaft für integrierte Kommunikationsforschung) jährlich
              durchführt.
            </Intro>
            <Intro>
              Bitte beschreiben Sie über folgende Attribute Ihre Zielgruppe,
              damit wir davon ausgehend Ihre PAC Ads (Paketbeilagen) optimal
              passenden Versandpartnern zuteilen können:
            </Intro>
          </StickyHeaderContent>
          <ColumnWrapper size={size}>
            <Column>
              <TargetGroupSection
                title="Geschlecht"
                name="gender"
                groups={groups}
              />
              <TargetGroupSection title="Alter" name="age" groups={groups} />
              <TargetGroupSection
                title="Kinder im Haushalt"
                name="children"
                groups={groups}
              />
            </Column>
            <Column>
              <TargetGroupSection
                title="Haushalts-Netto-Einkommen"
                name="income"
                groups={groups}
              />
            </Column>
          </ColumnWrapper>

          <TargetGroupSection
            title="Aussagen, die für Ihre Zielgruppe voll, ganz oder eher zutreffen"
            name="other"
            groups={groups}
          />

          <FooterNavigation
            step="targetGroups"
            disabledNext={!targetGroups.selected.length}
            errorText="Bitte wählen Sie mindestens eine Zielgruppe aus."
          />
        </Fragment>
      )}
    </Fragment>
  );
};
