// Modules
import React, { Fragment } from "react";
import { BrowserRouter, Route } from "react-router-dom";

// Screens
import { Footer } from "./screens/Footer";
import { Header } from "./screens/Header";
import Navigation from "./components/Navigation";

// Components
import { ContentContainer } from "./containers/Content";
import { Loading } from "./components/Loading";
import { MainContainer } from "./containers/Main";

// Custom
import { menuItems } from "./components/Navigation";

// Definitions
type Props = {
  isLoading: boolean;
};

export const Router = (props: Props) => {
  const { isLoading } = props;

  let containerContent = <Loading />;
  if (!isLoading) {
    containerContent = (
      <Fragment>
        {menuItems.map((menu) => (
          <Route
            path={menu.to}
            component={menu.component}
            key={`route_${menu.key}`}
            exact={menu.exact}
          />
        ))}
      </Fragment>
    );
  }

  return (
    <BrowserRouter>
      <Header />
      <Navigation />
      <ContentContainer>
        <MainContainer>{containerContent}</MainContainer>
      </ContentContainer>
      <Footer />
    </BrowserRouter>
  );
};
