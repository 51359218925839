// Modules
import React from "react";
import styled from "styled-components";

// Definitions
interface IProps {
  className?: string;
}

// Styles
const StyledSVG = styled.svg`
  fill: ${(props) => props.theme.defaultTextColor};
`;

// Component
export default (props: IProps) => {
  const { className = "" } = props;

  return (
    <StyledSVG width="9" height="14" viewBox="0 0 9 14" className={className}>
      <path d="M0.59375 7.65137L6.30469 13.3623C6.50065 13.5583 6.73861 13.6562 7.01855 13.6562C7.2985 13.6562 7.53646 13.5583 7.73242 13.3623L8.65625 12.3965C8.85221 12.2005 8.9502 11.9626 8.9502 11.6826C8.9502 11.4027 8.85221 11.1647 8.65625 10.9688L4.625 6.9375L8.65625 2.90625C8.85221 2.71029 8.9502 2.47233 8.9502 2.19238C8.9502 1.91243 8.85221 1.67448 8.65625 1.47852L7.73242 0.512695C7.53646 0.316732 7.2985 0.21875 7.01855 0.21875C6.73861 0.21875 6.50065 0.316732 6.30469 0.512695L0.59375 6.22363C0.397786 6.4196 0.299805 6.65755 0.299805 6.9375C0.299805 7.21745 0.397786 7.4554 0.59375 7.65137Z" />
    </StyledSVG>
  );
};
