// Modules
import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import DatePicker from "react-datepicker";

// External CSS
import "react-datepicker/dist/react-datepicker.css";

// Icons
import CalendarIcon from "../Icons/Calendar";

// Definitions
type IProps = {
  onChange?: any;
  className?: string;
  selectedDate?: any;
  minDate?: any;
};

// Styles
const GlobalStyle = createGlobalStyle`
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__day--selected {
    color: white!important;
    background-color: #00b1eb!important;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  border-color: ${(props) => props.theme.dark}
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 2px 8px;
  flex: 1;
  line-height: 30px;
  font-size: 16px;
  width: 220px;
  cursor: pointer;
`;

const StyledCalendarIcon = styled(CalendarIcon)`
  position: relative;
  background: ${(props) => props.theme.white};
  fill: ${(props) => props.theme.dark};
  padding: 6px 13px;
  border-radius: 0 4px 4px 0;
  top: 12px;
  right: 38px;
  width: 13px;

  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div``;

// Component / Hook
export const CustomDatePicker = (props: IProps) => {
  // Props
  const { onChange, minDate, selectedDate, className } = props;

  // States
  const [currentCalendar, setCurrentCalendar] = useState();

  return (
    <Wrapper>
      <GlobalStyle />
      <StyledDatePicker
        className={className}
        selected={selectedDate}
        onChange={onChange}
        dateFormat="dd.MM.YYYY"
        minDate={new Date(minDate)}
        ref={(c) => setCurrentCalendar(c)}
      />
      <StyledCalendarIcon
        onClick={() => {
          currentCalendar.setOpen(true);
        }}
      />
    </Wrapper>
  );
};
