// Modules
import React, { Fragment, useContext, useState, useEffect } from "react";
import { createGlobalStyle } from "styled-components";

// Modules
import { Router } from "./Router";

// Context
import { AppContext } from "./ContextProvider";

interface MyThemeProps {
  theme: any;
}

// Styles
const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    width: 100%;
    font-family: ${(props: MyThemeProps) => props.theme.defaultFontFamily};
  }

  a {
    color: ${(props: MyThemeProps) => props.theme.primaryColor};
    text-decoration: none;
  }
`;

export const App = () => {
  const { dispatch } = useContext(AppContext);
  const [isLoading, setLoading] = useState(true);

  const getConfig = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_BACKEND}/config`);
    const payload = await response.json();

    dispatch({
      payload,
      step: "config",
    });

    setLoading(false);
  };

  // Load data on start
  useEffect(() => {
    getConfig();
  }, []);

  return (
    <Fragment>
      <GlobalStyle />
      <Router isLoading={isLoading} />
    </Fragment>
  );
};
