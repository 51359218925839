// Modules
import React from "react";
import styled from "styled-components";
// @ts-ignore
import Loader from "react-loader-spinner";

// Styles
const LoadingWrapper = styled.div`
  margin: 10px auto;
  text-align: center;
`;

// Component / Hook
export const Loading = () => {
  return (
    <LoadingWrapper>
      <Loader type="ThreeDots" color="#00b1eb" height={60} width={60} />
    </LoadingWrapper>
  );
};
