// Modules
import React, { useContext } from "react";
import styled from "styled-components";

// Context
import { AppContext } from "../../ContextProvider";

// Components
import { ChooseMaterial } from "../../components/AdvertisingMaterial/ChooseMaterial";
import { ChoiceScreen } from "../../components/AdvertisingMaterial/ChoiceScreen";

// Styles
const Wrapper = styled.section``;

// Component / Hook
export const AdvertisingMaterial = () => {
  const { state } = useContext(AppContext);

  const content =
    state.advertisingMaterial && state.advertisingMaterial.selectedId ? (
      <ChoiceScreen />
    ) : (
      <ChooseMaterial />
    );

  return <Wrapper>{content}</Wrapper>;
};
