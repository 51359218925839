// Modules
import React from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

// Styles
const ToolTipWrapper = styled.span`
  border-radius: 50%;
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.light};
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  position: relative;
  top: -2px;
  left: 5px;

  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.span`
  .customToolTip {
    max-width: 500px;
  }
`;

// Definitions
type Props = {
  tip: string;
  className?: string;
};

// Component / Hook
export const ToolTip = (props: Props) => {
  const { className, tip } = props;

  return (
    <Wrapper>
      <ReactTooltip place="right" className="customToolTip" />
      <ToolTipWrapper data-tip={tip} className={className}>
        i
      </ToolTipWrapper>
    </Wrapper>
  );
};
