// Modules
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { format, addDays, differenceInCalendarDays } from "date-fns";

// Context
import { AppContext } from "../../ContextProvider";

// Components
import { CustomDatePicker } from "../CustomDatePicker";
// import { Intro } from "../Intro";
import { ToolTip } from "../ToolTip";

// Styles
const SelectWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.defaultMargin};
`;

const Error = styled.div`
  color: red;
  margin: 10px 0 20px 0;
`;

const StyledIntro = styled.span`
  margin-bottom: 0;
`;

// const Note = styled(Intro)`
//   margin-top: ${props => props.theme.defaultMargin};
// `;

// Helper
export const isValidVoucherDate = (
  selectedDate: any,
  startDate: any,
  minimum: number,
) =>
  differenceInCalendarDays(
    selectedDate,
    getMinimumVoucherDate(startDate, minimum),
  ) >= 0;

// Always start date + 12 weeks
export const getMinimumVoucherDate = (startDate: any, minimum: number) =>
  addDays(startDate, minimum);

// Component / Hook
export const VoucherStart = (props: any) => {
  // Get information from props
  const { info } = props;

  // Get state information
  const { dispatch, state } = useContext(AppContext);
  const { voucherStart, startDate } = state.circulation;

  // States
  const currentDate =
    voucherStart && voucherStart.date
      ? new Date(voucherStart.date)
      : getMinimumVoucherDate(startDate.date, info.day_validity_voucher);
  const [selectedDate, setSelectedDate] = useState(currentDate);

  const onChange = (selectedDate: Date) => {
    dispatch({
      payload: {
        voucherStart: {
          date: selectedDate,
        },
      },
      step: "circulation",
    });
    setSelectedDate(selectedDate);
  };

  // Calc defaults
  const minimumVoucherDate = format(
    getMinimumVoucherDate(startDate.date, info.day_validity_voucher),
    "DD.MM.YYYY",
  );

  // Use calculated date
  let noteVoucherText = "";
  if (info.note_voucher_validity) {
    noteVoucherText = info.note_voucher_validity.replace(
      "{{ DATUM }}",
      minimumVoucherDate,
    );
  }

  return (
    <SelectWrapper>
      <StyledIntro>
        Gutscheincode gültig bis: <ToolTip tip={noteVoucherText} />
      </StyledIntro>
      <CustomDatePicker
        selectedDate={selectedDate}
        onChange={onChange}
        minDate={voucherStart.minDate}
      />
      {!voucherStart.isValid && !info.note_voucher_validity && (
        <Error>Bitte überprüfen Sie Ihre Eingaben.</Error>
      )}
      {!voucherStart.isValid && info.note_voucher_validity && (
        <Error>
          Bitte beachten Sie folgenden Hinweis:
          <br />
          {noteVoucherText}
        </Error>
      )}
    </SelectWrapper>
  );
};
