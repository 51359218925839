// Modules
import React, { Fragment, useContext, useEffect, useState } from "react";
import styled from "styled-components";

// Context
import { AppContext } from "../../ContextProvider";

// Components
import { FooterNavigation } from "../../components/FooterNavigation";
import { Checkbox } from "../../components/Checkbox";
import { Title } from "../../components/Title";
import { Loading } from "../../components/Loading";
import {
  CirculationSelect,
  getMinimumStartDate,
  getMinimumVoucherDate,
  isValidStartDate,
  isValidVoucherDate,
  Price,
  StartDate,
  VoucherStart,
} from "../../components/Circulation";
import { StickyHeaderContent } from "../../components/StickyHeaderContent";

// Styles
const ColumnWrapper = styled.div`
  display: flex;
`;

const Column = styled.div`
  flex: 1;

  &:first-child {
    padding-right: 30px;
    max-width: 450px;
  }
`;

const StyledCirculationSelect = styled(CirculationSelect)`
  max-width: 520px;
`;

const CheckboxWrapper = styled.div`
  margin-top: ${(props) => props.theme.defaultMargin};
`;

// Component / Hook
export const Circulation = () => {
  // Get state information
  const {
    dispatch,
    state: {
      advertisingMaterial: { type, codetype, selectedId },
      circulation: {
        startDate = {
          date: null,
          minDate: null,
          isValid: false,
        },
        voucherStart = {
          date: null,
          minDate: null,
          isValid: false,
        },
        amount,
        amountId,
        feedback: oFeedback,
      },
    },
  } = useContext(AppContext);

  // Internal states
  const [info, setInfos] = useState({
    val_distribution: 0,
    day_voucher_validity: 0,
    note_delivery: "",
    note_voucher_validity: "",
    note_distribution: "",
  });
  const [feedback, setFeedback] = useState(oFeedback);

  const [isLoading, setIsLoading] = useState(true);

  // Data
  const loadCirculationInfos = async () => {
    if (!amount) return;
    setIsLoading(true);
    // Set query params depensd on which flow type we have
    const queryParams = type === "pp" ? `&codetype=${codetype}` : "";

    const response = await fetch(
      `${
        process.env.REACT_APP_API_BACKEND
      }/circulation?type=${type}&id=${selectedId}&amount=${amount}${queryParams}`,
    );

    const { data } = await response.json();
    setInfos(data);

    const { note_delivery, note_voucher_validity, note_distribution } = data;

    dispatch({
      payload: {
        note_delivery,
        note_distribution,
        note_validity: note_voucher_validity,
      },
      step: "circulation",
    });
    setIsLoading(false);
  };

  // Load data only on start
  useEffect(() => {
    loadCirculationInfos();
  }, [amountId]);

  const validateDates = () => {
    if (isLoading) return;
    console.log("validateDates", info.val_distribution);

    const newStartDate = {
      date: startDate.date || getMinimumStartDate(info.val_distribution),
      isValid: isValidStartDate(startDate.date, info.val_distribution),
      minDate: getMinimumStartDate(info.val_distribution),
    };

    const newVoucherDate = {
      date:
        voucherStart.date ||
        getMinimumVoucherDate(newStartDate.date, info.day_voucher_validity),
      isValid: isValidVoucherDate(
        voucherStart.date,
        newStartDate.date,
        info.day_voucher_validity,
      ),
      minDate: getMinimumVoucherDate(
        newStartDate.date,
        info.day_voucher_validity,
      ),
    };

    dispatch({
      payload: {
        startDate: newStartDate,
        voucherStart: newVoucherDate,
      },
      step: "circulation",
    });
  };

  useEffect(validateDates, [
    !isLoading && startDate.date,
    !isLoading && voucherStart.date,
    !isLoading && info.val_distribution,
  ]);

  const onSelectCheckbox = (options: any) => {
    const isChecked = options.target.checked;
    dispatch({
      payload: {
        feedback: isChecked,
      },
      step: "circulation",
    });
    setFeedback(isChecked);
  };

  // Check if user has vouchers
  let hasVoucher = false;
  if (type === "ep" && codetype === 99) {
    hasVoucher = true;
  } else if (type === "pp" && codetype && codetype > 0 && codetype < 4) {
    hasVoucher = true;
  }

  // Check if we can go on
  let disabledNext = startDate && !startDate.isValid;

  // If there is a voucher the date has to be valid and
  if (hasVoucher && (!voucherStart || !voucherStart.isValid)) {
    disabledNext = true;
  }

  return (
    <Fragment>
      <StickyHeaderContent>
        <Title label="Auflage & Streustart" />
      </StickyHeaderContent>
      <ColumnWrapper>
        <Column>
          <StyledCirculationSelect />
          {isLoading ? (
            <Loading />
          ) : (
            <Fragment>
              {amountId && startDate.date && <StartDate info={info} />}
              {amountId && hasVoucher && voucherStart.date && (
                <VoucherStart info={info} />
              )}
            </Fragment>
          )}
        </Column>
        <Column>{amountId && <Price />}</Column>
      </ColumnWrapper>
      {amountId && hasVoucher && (
        <CheckboxWrapper>
          <Checkbox
            label="Ja, ich verpflichte mich, nach Ablauf der Gutscheingültigkeit die Anzahl der Einlösungen je Gutscheincode an PACVERTISE zurückzumelden und erhalte für meinen Beitrag zur Optimierung zukünftiger Kampagnen eine Vergünstigung i.H.v. 5,00 € je tausend versendeter Beilagen."
            name="feedback"
            onSelect={onSelectCheckbox}
            checked={feedback}
          />
        </CheckboxWrapper>
      )}
      <FooterNavigation
        step="circulation"
        disabledNext={disabledNext}
        errorText="Bitte überprüfen Sie Ihre Angaben."
      />
    </Fragment>
  );
};
