// Modules
import React from "react";
import styled from "styled-components";

// Styles
interface IProps {
  isValid: boolean;
  validate: boolean;
}
const Text = styled.input<IProps>`
  background-color: ${(props) => {
    if (props.disabled) return props.theme.greyLight;
    if (!props.validate) return props.theme.backgroundInput;
    if (!props.isValid) return props.theme.backgroundInvalid;
    return props.theme.backgroundValid;
  }};
  border-color: ${(props) => {
    if (!props.validate) return props.theme.dark;
    if (!props.isValid) return props.theme.invalidColor;
    return props.theme.borderValid;
  }};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 2px 8px;
  flex: 1;
  line-height: 30px;
  font-size: 16px;
`;

const Error = styled.div`
  color: ${(props) => props.theme.invalidTextColor};
  margin-top: 5px;
  padding-left: 4px;
`;

const TextWrapper = styled.div`
  display: flex;
  max-width: 400px;
`;

const Label = styled.div`
  margin-bottom: 5px;
  padding-left: 5px;
`;

const Wrapper = styled.div`
  margin-bottom: ${(props) => props.theme.defaultMargin};
`;

// Definitions
type Props = {
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  fieldId: string;
  isValid?: boolean;
  label?: string;
  mandatory?: boolean;
  onChange: any;
  placeholder?: string;
  validate?: boolean;
  value: string;
};

// Component / Hook
export const TextField = (props: Props) => {
  const {
    className,
    disabled = false,
    errorMessage = "Bitte überprüfen Sie Ihre Eingaben.",
    fieldId,
    isValid = false,
    label,
    mandatory,
    onChange,
    placeholder = "",
    validate = false,
    value,
  } = props;

  const onChangeIntern = (options: any) => {
    const value = options.target.value;
    onChange({ value, fieldId });
  };

  return (
    <Wrapper>
      {label && (
        <Label>
          {label}
          {mandatory && " *"}
        </Label>
      )}
      <TextWrapper className={className}>
        <Text
          disabled={disabled}
          isValid={isValid}
          onChange={onChangeIntern}
          placeholder={placeholder}
          type="text"
          validate={validate}
          value={value}
        />
      </TextWrapper>
      {errorMessage && !isValid && validate && <Error>{errorMessage}</Error>}
    </Wrapper>
  );
};
