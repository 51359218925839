// Modules
import React, { ReactChild } from "react";
import styled from "styled-components";

// Styles
const Main = styled.div`
  padding: 0 20px;
  margin: 0 auto;
  max-width: 1240px;
`;

// Definitions
type IProps = {
  children: ReactChild | ReactChild[];
  className?: string;
};

// Component
export const MainContainer = (props: IProps) => {
  const { children, className = "" } = props;
  return <Main className={className}>{children}</Main>;
};
