// Modules
import { DefaultTheme } from "styled-components";

const myTheme: DefaultTheme = {
  // Colors
  primaryColor: "#00b1eb",
  primaryColorDark: "#0485b0",
  defaultTextColor: "#000",
  dark: "#000",
  light: "#FFF",
  greyLight: "#EFEFEF",
  borderColor: "#CCC",
  green: "#95c120",
  backgroundLight: "#ededed",
  lightLink: "#c9c9c9",
  backgroundValid: "#0080001a",
  borderValid: "#00800059",
  backgroundInvalid: "#ff00001a",
  invalidColor: "#7c0c0c6e",
  invalidTextColor: "#7c0c0c",
  backgroundInput: "hsl(0, 0%, 100%)",
  borderInput: "hsl(0, 0%, 80%)",
  darkGrey: "#ADADAD",

  // Distances
  defaultMargin: "20px",
  largeMargin: "40px",
  textLineHeight: "22px",

  // Fonts
  defaultFontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  textFontSize: "15px",

  // Media queries
};

export default myTheme;
