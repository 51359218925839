// Modules
import React from "react";
import styled from "styled-components";

// Definitions
interface IProps {
  className?: string;
}

// Styles
const StyledSVG = styled.svg`
  fill: ${(props) => props.theme.greyLight};
`;

// Component
export default (props: IProps) => {
  const { className = "" } = props;

  return (
    <StyledSVG
      width="88"
      height="125"
      viewBox="0 0 88 125"
      fill="none"
      className={className}
    >
      <mask id="path-1-inside-1" fill="white">
        <rect width="88" height="125" rx="2" />
      </mask>
      <rect
        width="88"
        height="125"
        rx="2"
        fill="#EFEFEF"
        stroke="#00B1EB"
        strokeWidth="6"
        mask="url(#path-1-inside-1)"
      />
    </StyledSVG>
  );
};
