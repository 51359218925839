// Modules
import React, { Fragment, useContext } from "react";
import styled from "styled-components";
import { useResponsive } from "react-hooks-responsive";

// Context
import { AppContext } from "../../ContextProvider";

// Config
import { breakpoints } from "../../config";

// Components
import { FormatItem } from "./FormatItem";
import { Title } from "../../components/Title";
import { StickyHeaderContent } from "../../components/StickyHeaderContent";

// Styles
const Section = styled.div`
  margin-bottom: 30px;
`;

const ItemWrapper = styled.div<{ size: string }>`
  display: ${(props) => (props.size === "md" ? "flex" : "block")};
  align-content: space-between;
  align-items: center;
  justify-content: flex-start;
`;

// Component / Hook
export const ChooseMaterial = () => {
  // Context
  const {
    state: {
      config: {
        advertisingMaterial: { pp = [], ep = [] },
      },
    },
  } = useContext(AppContext);

  // States
  const [size] = useResponsive(breakpoints);

  return (
    <Fragment>
      <StickyHeaderContent>
        <Title label="Werbemittel" />
      </StickyHeaderContent>
      <Section>
        <Title label="Ich möchte drucken lassen" isSmall />
        <ItemWrapper size={size}>
          {pp.map((item: any) => (
            <FormatItem item={item} key={item.format} type="pp" />
          ))}
        </ItemWrapper>
      </Section>
      <Section>
        <Title label="Ich liefere selbst an" isSmall />
        <ItemWrapper size={size}>
          {ep.map((item: any) => (
            <FormatItem item={item} key={item.format} type="ep" />
          ))}
        </ItemWrapper>
      </Section>
    </Fragment>
  );
};
