// Modules
import React from "react";
import styled from "styled-components";
import Select from "react-select";

// Styles
const Wrapper = styled.div``;

const StyledSelect = styled(Select)`
  max-width: 50%;
  margin-bottom: ${(props) => props.theme.defaultMargin};

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__control {
    border-radius: 4px;
    border-width: 1px;
    border-color: ${(props) => props.theme.dark};

    &:hover {
      border-color: ${(props) => props.theme.primaryColor};
      cursor: pointer;
    }
  }

  .react-select__dropdown-indicator {
    background-color: transparent;
    svg {
      fill: ${(props) => props.theme.dark};
    }
  }

  .react-select__option--is-selected {
    background: ${(props) => props.theme.primaryColor};
  }

  .react-select__option:hover {
    background: ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.light};
    cursor: pointer;
  }
`;

// Definitions
interface IProps {
  options: any;
  onChange: any;
  value: any;
  placeholder: any;
  className?: string;
}

// Component / Hook
export const SelectField = (props: IProps) => {
  const { options, onChange, value, placeholder, className } = props;

  return (
    <Wrapper className={className}>
      <StyledSelect
        className="react-select-container"
        classNamePrefix="react-select"
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        value={value}
      />
    </Wrapper>
  );
};
