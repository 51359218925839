// Modules
import React from "react";
import styled from "styled-components";

// Definitions
interface IProps {
  className?: string;
}

// Styles
const StyledSVG = styled.svg`
  fill: ${(props) => props.theme.light};
`;

// Component
export default (props: IProps) => {
  const { className = "" } = props;

  return (
    <StyledSVG viewBox="0 0 20 22" width="20" height="22" className={className}>
      <path d="M19.5145 7.09152C19.5145 6.7846 19.3917 6.47768 19.1708 6.2567L17.5011 4.58705C17.2801 4.36607 16.9732 4.2433 16.6663 4.2433C16.3594 4.2433 16.0525 4.36607 15.8315 4.58705L7.7779 12.6529L4.16853 9.03125C3.94754 8.81027 3.64063 8.6875 3.33371 8.6875C3.02679 8.6875 2.71987 8.81027 2.49888 9.03125L0.829241 10.7009C0.608259 10.9219 0.485491 11.2288 0.485491 11.5357C0.485491 11.8426 0.608259 12.1496 0.829241 12.3705L5.27344 16.8147L6.94308 18.4844C7.16406 18.7054 7.47098 18.8281 7.7779 18.8281C8.08482 18.8281 8.39174 18.7054 8.61272 18.4844L10.2824 16.8147L19.1708 7.92634C19.3917 7.70536 19.5145 7.39844 19.5145 7.09152Z" />
    </StyledSVG>
  );
};
