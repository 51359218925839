// Modules
import React, { useContext, useState, useEffect, Fragment } from "react";
import styled from "styled-components";

// Context
import { AppContext } from "../../ContextProvider";

// Components
import { Checkbox } from "../Checkbox";
import { FooterNavigation } from "../FooterNavigation";
import { Intro } from "../Intro";
import { Loading } from "../Loading";
import { RadioButton } from "../RadioButton";
import { SelectField } from "../SelectField";
import { Title } from "../Title";
import { StickyHeaderContent } from "../../components/StickyHeaderContent";

// Styles
const Wrapper = styled.div``;
const CheckboxWrapper = styled.div`
  margin-top: ${(props) => props.theme.defaultMargin};
`;

const StyledSection = styled.section`
  margin-bottom: 30px;
`;

const StyledSelectField = styled(SelectField)`
  max-width: 320px;
  .react-select-container {
    max-width: 100%;
  }
`;

// Helper
const replaceAll = (target: string, search: string, replacement: any) => {
  if (!target) return "";
  return target.replace(new RegExp(search, "g"), replacement);
};

// Component / Hook
export const ChoiceEP = () => {
  const [isLoading, setLoading] = useState(true);
  const [acceptConditions, setAcceptConditions] = useState(false);
  const [weightOptions, setWeightOptions] = useState([]);
  const [note, setNote] = useState("");
  const [noMax, setNoMax] = useState(0);

  const {
    dispatch,
    state: { advertisingMaterial },
  } = useContext(AppContext);

  const { format, selectedId, codetype, weight } = advertisingMaterial;

  const getOptions = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BACKEND}/epinfos/${selectedId}`,
    );
    const { weight: weightOptions, note_delivery } = await response.json();

    const selectedItem = weightOptions.find(
      (element: any) => element.weight === weight,
    );
    setNoMax(selectedItem ? selectedItem.no_max : 0);
    setNote(note_delivery);
    setWeightOptions(weightOptions);
    setLoading(false);
  };

  // Load data on start
  useEffect(() => {
    getOptions();
  }, []);

  const setStateInStore = (key: string, value: any) => {
    const payload: any = {};
    payload[key] = value;

    // Temp: Save current message in store
    payload.note_devliery = note;

    dispatch({
      payload,
      step: "advertisingMaterial",
    });
  };
  const onChangeSelect = (options: any) => {
    setNoMax(options.no_max);
    setStateInStore("weight", parseInt(options.value, 10));
  };

  const onSelectCheckbox = (options: any) => {
    const isChecked = options.target.checked;
    setStateInStore("acceptDelivery", isChecked);
    setAcceptConditions(isChecked);
  };

  const onChangeVoucher = (options: any) =>
    setStateInStore("codetype", parseInt(options.target.value, 10));

  let selectOptions;
  let selectedWeightValue;
  if (!isLoading) {
    selectOptions = weightOptions.map((optionWeight) => {
      // @ts-ignore
      const weightOption = optionWeight.weight;

      const item = {
        value: weightOption,
        label: `bis ${weightOption} g`,
        // @ts-ignore
        no_max: optionWeight.no_max,
      };

      if (weightOption === weight) {
        selectedWeightValue = item;
      }
      return item;
    });
  }

  // Check to display next button
  const disabledNext = !(
    codetype !== null &&
    codetype !== undefined &&
    acceptConditions &&
    weight
  );
  // codetype !== null && acceptConditions && weight !== null ? false : true;

  console.log("weight", weight);

  const onBeforePrev = () =>
    new Promise(async (resolve) => {
      dispatch({
        stepAction: "reset",
        step: "advertisingMaterial",
      });

      // Answer correctly to footer navigation can move to next element
      resolve(true);
    });

  const replacedNote = replaceAll(note, "{{ STUECK }}", noMax.toLocaleString());

  return (
    <Wrapper>
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <StickyHeaderContent>
            <Title label="Werbemittel" />
          </StickyHeaderContent>
          <StyledSection>
            <Intro>
              Ich liefere meine Werbemittel selbst an. Ich liefere {format}.
            </Intro>
          </StyledSection>
          <StyledSection>
            <Title label="Gewicht" isSmall />
            <Intro>Bitte wählen Sie das Gewicht je Werbemittel:</Intro>
            <CheckboxWrapper>
              <StyledSelectField
                options={selectOptions}
                onChange={onChangeSelect}
                placeholder="Bitte wählen Sie das Gewicht aus"
                value={selectedWeightValue}
              />
            </CheckboxWrapper>
          </StyledSection>
          <StyledSection>
            <Title label="Gutscheincode" isSmall />
            <RadioButton
              label="Mit den Werbemitteln wird ein Gutscheincode ausgegeben"
              value={99}
              name="codetype"
              onSelect={onChangeVoucher}
              selectedValue={codetype}
            />
            <RadioButton
              label="Die Werbemittel beinhalten keinen Gutscheincode"
              value={0}
              name="codetype"
              onSelect={onChangeVoucher}
              selectedValue={codetype}
            />
          </StyledSection>
          <StyledSection>
            <Title label="Anlieferbedingungen" isSmall />
            <Intro>{replacedNote}</Intro>
            <Checkbox
              label="Ja, ich habe die <a href='https://www.paketplus.de/Anlieferbedingungen.pdf' target='_blank' />Anlieferbedingungen</a> gelesen und verstanden, und ja, ich erkläre mich mit der Übernahme von aus der Nichteinhaltung dieser Anlieferbedingungen resultierenden Mehrkosten einverstanden."
              name="accept-devlivery"
              onSelect={onSelectCheckbox}
              checked={acceptConditions}
            />
          </StyledSection>
          <FooterNavigation
            step="advertisingMaterial"
            disabledNext={disabledNext}
            onBeforePrev={onBeforePrev}
            errorText="Bitte prüfen Sie Ihre Eingaben (Gewicht, Gutscheincode und Anlieferbedingungen)."
          />
        </Fragment>
      )}
    </Wrapper>
  );
};
