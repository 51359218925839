// Modules
import React from "react";
import styled from "styled-components";

// Definitions
interface IProps {
  className?: string;
}

// Styles
const StyledSVG = styled.svg``;

// Component
export default (props: IProps) => {
  const { className = "" } = props;

  return (
    <StyledSVG
      width="92"
      height="146"
      viewBox="0 0 92 146"
      fill="none"
      className={className}
    >
      <path
        d="M88 144H4C2.89543 144 2 143.105 2 142V19L39.1793 2.26933C40.5028 1.67376 42 2.64186 42 4.09317V10.5L79.6314 3.44411C80.8623 3.21333 82 4.15758 82 5.40985V19H88C89.1046 19 90 19.8954 90 21V142C90 143.105 89.1046 144 88 144Z"
        fill="#EFEFEF"
      />
      <path
        d="M2 19V142C2 143.105 2.89543 144 4 144H88C89.1046 144 90 143.105 90 142V21C90 19.8954 89.1046 19 88 19H82M2 19L39.1793 2.26933C40.5028 1.67376 42 2.64186 42 4.09317V10.5M2 19L42 10.5M2 19H82M42 10.5L79.6314 3.44411C80.8623 3.21333 82 4.15758 82 5.40985V19"
        stroke="#00B1EB"
        strokeWidth="3"
      />
    </StyledSVG>
  );
};
