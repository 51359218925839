// Modules
import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useResponsive } from "react-hooks-responsive";

// Config
import { breakpoints } from "../../config";

// Icons
import CheckIcon from "../Icons/Check";

// Definitions
type Props = {
  to: string;
  index: number;
  name: string;
  isDone: boolean;
  isClickable: boolean;
  isActive: boolean;
  isFinal: boolean;
};

// Styles without any component props
const StyledLink = styled(NavLink)`
  text-decoration: none;
  text-align: center;
  flex: 1;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 22px;
    height: 2px;
    background-color: ${(props) => props.theme.primaryColor};
    width: 100%;
    z-index: 0;
  }

  &:first-child::before {
    content: "";
    position: absolute;
    top: 22px;
    height: 2px;
    background-color: ${(props) => props.theme.primaryColor};
    width: 100%;
    left: -50%;
    z-index: 0;
  }
`;

const LinkContent = styled.div`
  color: ${(props) => props.theme.defaultTextColor};
  margin-right: 10px;
`;

// Component / Hook
export default (props: Props) => {
  const [size] = useResponsive(breakpoints);

  // Get all props
  const { to, index, name, isActive, isDone, isClickable, isFinal } = props;

  // Only render items on desktop mode
  if ((size === "xs" || size === "sm") && !isActive) return null;

  // Styles
  const FakeLink = styled.div`
    cursor: default;
    text-decoration: none;
    text-align: center;
    flex: 1;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 22px;
      width: 4em;
      height: 2px;
      background-color: ${(props) => {
        if (isFinal) return props.theme.primaryColor;
        return props.theme.darkGrey;
      }};
      width: 100%;
      z-index: 0;
    }
  `;

  const Circle = styled.div`
    border-radius: 50%;
    background-color: ${(props) => {
      if (isActive && !isFinal) return props.theme.light;
      if (isFinal) return props.theme.primaryColor;
      if (isDone && isClickable) return props.theme.primaryColor;
      return props.theme.light;
    }};
    border: 1px solid
      ${(props) => {
        if (isFinal) return props.theme.primaryColor;
        if ((isActive || isDone) && isClickable)
          return props.theme.primaryColor;
        return props.theme.darkGrey;
      }};
    color: ${(props) => {
      if (isActive) return props.theme.primaryColor;
      if (isFinal) return props.theme.light;
      // if (isDone) return props.theme.light;
      return props.theme.darkGrey;
    }};
    width: 48px;
    height: 48px;
    text-align: center;
    margin: 0 auto 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
  `;

  const Name = styled.div`
    font-weight: ${() => (isActive ? "bold" : "normal")};
    color: ${(props) => {
      if (isFinal) return props.theme.primaryColor;
      if (isActive || (isDone && isClickable)) return props.theme.primaryColor;
      return props.theme.darkGrey;
    }};
  `;

  // Check if link is clickable so get a fake link
  const LinkElement = isClickable && !isFinal ? StyledLink : FakeLink;

  let circleContent;
  if ((isDone && !isActive && isClickable) || isFinal) {
    circleContent = <CheckIcon />;
  } else {
    circleContent = <Fragment>{index + 1}</Fragment>;
  }

  return (
    <LinkElement to={to}>
      <LinkContent>
        <Circle>{circleContent}</Circle>
        <Name>{name}</Name>
      </LinkContent>
    </LinkElement>
  );
};
