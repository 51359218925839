// Modules
import React from "react";

// Images
import Icon from "../../images/material_pp_2.png";

// Definitions
interface IProps {
  className?: string;
}

// Component
export default (props: IProps) => {
  const { className = "" } = props;

  return <img src={Icon} className={className} />;
};
